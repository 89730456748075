import service from '@/utils/request2'

//获取考试列表
export function getExaminationList(params) {
    return service({
        url: '/api/api/examination/objectiveList',
        method: 'get',
        params
    })
}
//获取考试下的100道题
export function getExaminationDetails(params) {
    return service({
        url: '/api/api/examination/objectiveInfo',
        method: 'get',
        params
    })
}
//获取每一道选择题的详情
export function getSubjectInfo(params) {
    return service({
        url: '/api/api/examination/getSubjectInfo',
        method: 'get',
        params
    })
}
//提交选择题的答题结果
export function objectiveSubmit(data) {
    return service({
        url: '/api/api/examination/objectiveSubmit',
        method: 'post',
        data
    })
}
//提交主观答案
export function Submit(data) {
    return service({
        url: '/api/api/examination/subjectiveSubmit',
        method: 'post',
        data
    })
}

//修改主观答案
export function reviseSupervisorAnswerDetails(data) {
    return service({
        url: '/api/api/examination/editSubjective',
        method: 'post',
        data
    })
}

//获取主观答案详情 
export function getSupervisorAnswerDetails(params) {
    return service({
        url: '/api/api/judges/answerInfo',
        method: 'get',
        params
    })
}


