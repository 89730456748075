<template>
    <div>
        <Menu></Menu>
        <div class="w1200 m-auto">
            <!-- 专业考试 -->
            <div>
                <div class="boxs">
                    <div class="ExamListText mt20">
                        专业考试
                    </div>

                    <div class="formBox">
                        <p v-html="content" class="content"></p>
                        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
                            <el-form-item label="作品名称1 ：" prop="works_one_title">
                                <el-input v-model="ruleForm.works_one_title"></el-input>
                            </el-form-item>
                            <el-form-item label="作品简介1 ：" prop="works_one_introduce">
                                <el-input type="textarea" v-model="ruleForm.works_one_introduce"></el-input>
                            </el-form-item>
                            <el-form-item label="作品1 ：" prop="thumb">
                                <div style="width:446px;">
                                    <div>
                                        <Upload :imgUrl="ruleForm.works_one_src" @change="getImg" />
                                    </div>
                                </div>
                            </el-form-item>
                            <el-form-item label="作品名称2 ：" prop="works_two_title">
                                <el-input v-model="ruleForm.works_two_title"></el-input>
                            </el-form-item>
                            <el-form-item label="作品简介2 ：" prop="works_two_introduce">
                                <el-input type="textarea" v-model="ruleForm.works_two_introduce"></el-input>
                            </el-form-item>
                            <el-form-item label="作品2 ：" prop="thumb">
                                <div style="width:446px;">
                                    <div>
                                        <!-- <Upload @change="getImg1" :imgUrl="ruleForm.works_two_src" /> -->
                                        <Upload :imgUrl="ruleForm.works_two_src" @change="getImg1" />
                                    </div>
                                </div>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div v-if="$route.query.answer_id" class="Submit" @click="reviseSubmitFn('ruleForm')">
                        修改答案
                    </div>
                    <div v-else class="Submit" @click="SubmitFn('ruleForm')">
                        提交答案
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { Submit, reviseSupervisorAnswerDetails, getSupervisorAnswerDetails } from "@/api/examination"
export default {
    data() {
        return {
            ruleForm: {
                works_one_src: '',
                works_two_src: ''
            },
            content: '',
            rules: {
                works_one_title: [
                    { required: true, message: '请输入作品名称', trigger: 'blur' },
                ],
                works_one_introduce: [
                    { required: false, message: '请输入作品名称', trigger: 'blur' },
                ],
                works_two_title: [
                    { required: true, message: '请输入作品简介', trigger: 'blur' },
                ],
                works_two_introduce: [
                    { required: false, message: '请输入作品简介', trigger: 'blur' },
                ],
            }
        }
    },
    mounted() {
        this.content = this.$route.query.content
        if (this.$route.query.answer_id) {
            getSupervisorAnswerDetails({ student_id: this.$route.query.student_id }).then(res => {
                if (res.code == 200) {
                    this.ruleForm = res.data
                }
            })

        }
    },
    methods: {
        getImg(val) {
            this.ruleForm.works_one_src = val
        },
        getImg1(val) {
            this.ruleForm.works_two_src = val
        },
        //修改答案
        reviseSubmitFn(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.ruleForm.answer_id = this.$route.query.answer_id
                    reviseSupervisorAnswerDetails(this.ruleForm).then(res => {
                        if (res.code == 200) {
                            this.$message.success('修改成功');
                            setTimeout(() => {
                                this.$router.go(-1)
                            }, 500);

                        }
                    })

                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        //提交答案
        SubmitFn(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.ruleForm.examination_id = this.$route.query.id
                    this.ruleForm.student_id = this.$route.query.student_id
                    Submit(this.ruleForm).then(res => {
                        if (res.code == 200) {
                            this.$message.success('提交成功');
                            setTimeout(() => {
                                this.$router.go(-1)
                            }, 500);
                        }
                    })

                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
    }
}
</script>
  
<style  scoped>
.buduil {
    width: 200px;
    height: 200px;
    background: #FFFFFF;
    border: 1px solid #D6D6D6;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.boxs {
    margin-top: 20px;
    width: 1200px;
    padding: 20px;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #E6E6E6;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.content {
    width: 194px;
    height: 40px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
}


.ExamListText {
    justify-content: center;
    color: #CD0000;
    display: flex;
    align-items: center;
    font-size: 22px;
}

.ExamListText::before {
    content: '';
    width: 80px;
    display: inline-block;
    margin-right: 20px;
    height: 2px;
    background: #CD0000;
}

.ExamListText::after {
    content: '';
    width: 80px;
    display: inline-block;
    height: 2px;
    margin-left: 20px;
    background: #CD0000;
}


.Submit {
    width: 440px;
    cursor: pointer;
    height: 48px;
    background: #CC0000;
    border-radius: 8px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
    margin: 100px auto;
    line-height: 48px;
    margin-bottom: 0px;
}



.formBox {
    width: 80%;
    margin: 20px auto;
}

.formBox>>>.el-form-item__label {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
}

.formBox>>>.el-input__inner {
    height: 50px;
    background: #FFFFFF;
    border: 1px solid #D6D6D6;
    border-radius: 4px;
}

.formBox>>>.el-textarea__inner {
    height: 178px;
    background: #FFFFFF;
    border: 1px solid #D6D6D6;
    border-radius: 4px;
}
</style>